<template>
    <div>
        <h6>You have selected {{this.selected.length}} order{{this.selected.length > 1 ? 's':'' }} to update status</h6>
        <div class="form-group mb-3">
            <label class="">Status</label>
            <select class="form-select" v-model="data.status" name="status" placeholder="--select status--">
                <option disabled value="" key="-1">-- select status--</option>
                <option :value="status.value" v-for="(status, index) in statusList" :key="index">{{status.name}}</option>
            </select>
        </div>
        <div class="form-group text-right mt-3">
            <b-button variant="dark" @click.prevent="updateStatus()" type="button">Update</b-button>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            data:{
                status:""
            },
            statusList:[
                {name: "Draft", value:"draft"},
                {name: "Pending", value:"pending"},
                {name: "Delivered", value:"delivered"},
                {name: "Completed", value:"completed"},
                {name: "refunded", value:"refunded"},
            ]
        }
    },
    props:{
        selected:{
            type: Array
        }
    },
    methods:{
        updateStatus() {
            if(!this.data.status) { this.alertError("Select status"); return;}
            Swal.fire({
                title: "Are you sure?",
                text: "Please confirm this action before proceed",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#1abc9c",
                cancelButtonColor: "#111",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    const formData = new FormData()
                    formData.append('data', JSON.stringify({orders: this.selected, status: this.data.status}))
                    this.$store.dispatch("changeLoaderValue",true)
                    this.$http.post(`/orders/update-status`,formData)
                    .then(response => {
                        this.$store.dispatch("changeLoaderValue",false)
                        if(response.data.success){
                            this.$store.dispatch("orderList/fetchOrders", true)
                            this.$emit('feedback');
                        }
                    })
                }
            });
        },
    }
}
</script>