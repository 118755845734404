
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of orders</h5>
      <div class="row mb-3 gy-2">
        <div class="col-sm-12 col-md-6 d-flex">
          <div id="tickets-table_length" class="me-2 dataTables_length">
            <label class="d-inline-flex mb-0 align-items-center">
              <b-form-select class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            </label>
          </div>
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ri ri-filter-3-fill"></i>
            </a>
            <ul class="dropdown-menu">
              <li>
                <button @click="selectAllRows()" class="dropdown-item" type="button">
                  {{isSelectAll? 'Invert selection' : 'Select all' }}
                </button>
              </li>
              <li @click="updateStatus()">
                <button class="dropdown-item" type="button">
                  Update Status
                </button>
              </li>
              <li @click="marketingMail()">
                <button class="dropdown-item" type="button">
                  Send e-Mail
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchOrders()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <label class="mb-2">Filter by status</label>
          <div>
            <div v-for="(status, sIndex) in statusOptions" :key="sIndex"
               class="form-check form-check-inline">
              <input v-model="defaultStatus" :id="`inlineRLRadio_${status.value}`" class="form-check-input" 
              type="radio" name="inlineRadioOptions" :value="status.value">
              <label class="form-check-label" :for="`inlineRLRadio_${status.value}`">{{status.label}}</label>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="orders"
                :fields="columns" responsive="sm">
                <template v-slot:cell(check)="data">
                  <div style="width:3.5rem;" class="form-check text-center me-auto">
                    <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input me-2" :id="`tableSRadio${data.item.id}`" />
                    <label class="form-check-label" :for="`tableSRadio${data.item.id}`">{{data.item.id}}</label>
                  </div>
                </template>
                <template v-slot:cell(number)="data">
                  <div @click="navigateTo(data.item.id)" class="cursor-pointer">
                    #{{ data.item.number }}
                  </div>
                </template>
                <template v-slot:cell(buyer)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer" v-if="data.item.buyer">
                    <span class="text-dark font-sm">{{ data.item.buyer.first_name }} {{ data.item.buyer.last_name }}</span>
                  </div>
                </template>
                <template v-slot:cell(product)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer" v-if="data.item.product">
                    <span class="text-dark font-sm">{{ data.item.product.name }}</span>
                  </div>
                </template>
                <template v-slot:cell(price)="data">
                  <div @click="navigateTo(data.item.id)" style="width:5rem;" class="cursor-pointer">
                    ${{ $filters.money_format(data.item.amount) }}
                  </div>
                </template>
                <template v-slot:cell(date)="data">
                  <div @click="navigateTo(data.item.id)" style="width:6rem;" class="cursor-pointer">
                    {{$filters.date_time(data.item.created_at, 2) }} 
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <div class="dropdown dropstart no-arrow">
                    <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                      role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <button @click="recheckPayment(data.item)" class="dropdown-item" type="button">
                          Recheck Payment
                        </button>
                      </li>
                      <li>
                        <button @click="deleteItem(data.item.id)" class="dropdown-item" type="button">
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>

    <b-modal size="md" title="Update Status"
      body-bg-variant="white" body-class="p-3"
        hide-footer v-model="modalUpdateStatus">
      <div>
        <order-status-form v-if="modalUpdateStatus" :selected="selected" @feedback="initializeAll()" />
      </div>
    </b-modal>
    <b-modal size="lg" title="Marketing Mail"
      body-bg-variant="white" body-class="p-3"
        hide-footer v-model="modalMarketingMail">
      <div>
        <marketing-mail-form v-if="modalMarketingMail"
        :selectedUsers="selectedUsers"
        @feedback="initializeAll()" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrderStatusForm from "@/components/forms/OrderStatusForm.vue";
import MarketingMailForm from "@/components/forms/MarketingMailForm.vue";
import IsLoading from '../IsLoading.vue';
import _ from 'lodash';

export default {
  name: "order-list",
  components:{
    OrderStatusForm,
    MarketingMailForm,
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      defaultStatus:"all",
      searchQuery:"",
      statusOptions:[
        { value: "all", label: "All", },{
          value: "draft", label: "Draft",
        },{ value: "pending", label: "Pending" },{
          value: "delivered", label: "Delivered",
        },{ value: "completed", label: "Completed",},{
          value: "refunded", label: "Refunded",
        }
      ],
      pageOptions: [10, 25, 50, 100],
      isSelectAll: false,
      columns: [
        {
            key: "check",
            label: "ID"
        },
        {
            key: "number",
            label: "Number",
            sortable: true
        },
        {
            key: "buyer",
            label: "Buyer",
            sortable: true
        },
        {
            key: "product",
            label: "Product",
            sortable: true
        },
        {
            key: "price",
            label: "Price",
            sortable: true
        },
        {
            key: "date",
            label: "Date",
            sortable: true
        },
        "action"
      ],
      selected: [],
      modalUpdateStatus: false,
      modalMarketingMail: false,
      selectedUsers: []
    }
  },
  watch: {
    '$route.query': function (query){
      this.refreshQueryStatus()
    },
    defaultStatus (val) {
      this.fetchOrders()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.orderList.pageData
    },
    orders() {
      return this.pageData.data.filter((order) => this.defaultStatus == "all" 
            || (order.status == this.defaultStatus))
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchOrders(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchOrders(1, val)
      }
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/orders/${id}`});
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.orders.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    refreshQueryStatus(){
      if(this.$route.query.status){
        this.defaultStatus = this.$route.query.status
      }
    },
    updateStatus(){
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      this.modalUpdateStatus = true
    },
    async marketingMail(){
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      this.selectedUsers = this.orders.filter((ord) => this.selected.includes(ord.id)).map((ord) => {
        return {id: ord.buyer.id, first_name: ord.buyer.first_name, email: ord.buyer.email,}
      })
      this.modalMarketingMail = true
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchOrders()
    }, 2000),
    fetchOrders(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus != 'all'){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrders", payload)
      .then(response => this.isLoading = false)
    },
    deleteItem(itemId){
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete an order permanently",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/orders/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit("orderList/DELETE_ORDER",itemId)
            }
          })
        }
      });
    },
    recheckPayment(item){
      Swal.fire({
        title: 'Provide Payment Intent Id',
        input: 'text',
        inputLabel: 'Payment Id',
        inputValue: item.payment_intent_id,
        inputPlaceholder: 'Enter Payment Id...',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value) {
            return 'Payment Id is required!'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.get(this.absoluteUrl(`/api/orders/${item.id}/check-payment?payment_id=${result.value}`))
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('orderList/UPDATE_ORDER', response.data.data)
            }
          })
        }
      })
    },
    initializeAll(){
      this.modalUpdateStatus = false,
      this.modalMarketingMail = false
      this.selectedUsers = []
    }
  },
  mounted() {
    this.refreshQueryStatus()
    if(this.defaultStatus == "all"){ this.fetchOrders() }
  },
}

</script>

