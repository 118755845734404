<template>
  <div>
    <div class="row gy-3 justify-content-start">
      <div class="col-12">
        <label for="subject">
            Subject
        </label>
        <input type="text" v-model="data.subject" id="subject"
            class="form-control" placeholder="Write subject" name="subject"/>
      </div>
      <div class="col-12">
        <div class="row pb-5">
          <div class="col-12">
            <quill-editor style="min-height:100px;"
              v-model:value="data.content"
              :options="editorOption"/>
          </div>
        </div>
      </div>
      <div class="col-12 pt-3">
        <label class="me-2"><b>{{selectedUsers.length}}</b> Selected User(s)</label>
        <a class="text-warning"
          data-bs-toggle="collapse"
          href="#showSelectedUHPR"
          role="button"
          aria-expanded="true"
          aria-controls="showSelectedUHPR">
          Click to view selected users
        </a>
        <div class="collapse" id="showSelectedUHPR">
          <div class="g-3 row">
            <div v-for="(user, uIndex) in selectedUsers" :key="uIndex" class="col-lg-6">
              <div class="p-3 bg-light">
                <h6>{{user.first_name}}</h6>
                <p class="mb-0">{{user.email}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="text-left mb-0">
          <button class="btn btn-dark" @click.prevent="createData()" type="button">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue3-quill'
export default{
  props:{
    selectedUsers:{
      type: Array,
      required: true
    }
  },
  components:{
    quillEditor
  },
  data() {
    return {
      data: {
        content: '',
        subject: "",
        buyers: this.selectedUsers
      },
      editorOption: {
        placeholder: 'Compose mail',
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ['clean'],
          ['link']
        ]
      },
    }
  },
  methods: {
    createData() {
      if (!this.data.subject || !this.data.content) { this.alertError("Form not valid"); return;}
      const formData = new FormData()
      formData.append('data', JSON.stringify(this.data))
      this.$store.dispatch("changeLoaderValue", true)
          this.$http.post("/orders/send-mail", formData)
          .then((response) => {
              this.$store.dispatch("changeLoaderValue", false)
              if(response.data.success){
                this.$emit('feedback');
                this.resetForm();
              }
          })
    },
    resetForm() {
      this.data = {
        content: "",
        subject: "",
        buyers: this.selectedUsers,
      }
    },
  },
}
</script>
